import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-select-account-dialog',
    templateUrl: './select-account-dialog.component.html',
    styleUrls: ['./select-account-dialog.component.sass']
})
export class SelectAccountDialogComponent implements OnInit {
    public filteredData;

    constructor(public dialogRef: MatDialogRef<SelectAccountDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.filteredData = data.slice();
    }

    public applyFilter(filter) {
        filter = filter.replace(/\s/g, '').toLowerCase().trim();
        this.filteredData = this.data.filter((v) => {
            const name = v.name.replace(/\s/g, '').toLowerCase().trim();
            const uuid = v.uuid.replace(/\s/g, '').toLowerCase().trim();
            return filter === '' || name.indexOf(filter) > -1 || uuid.indexOf(filter) > -1;
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(account) {
        this.dialogRef.close(account);
    }

    ngOnInit() {
    }

}
