import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {Subject} from 'rxjs/Subject';
import {IUser} from '../../../interfaces/iuser';
import {AccountService} from '../../../services/account.service';
import {IAccount} from '../../../interfaces/iaccount';
import {SalesforceService} from '../../../services/salesforce.service';
import {NewCaseDialogComponent} from '../../dialogs/new-case-dialog/new-case-dialog.component';
import {MatDialog, MatSnackBar} from '@angular/material';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {CaseCommentDialogComponent} from '../../dialogs/case-comment-dialog/case-comment-dialog.component';
import {ConfirmationDialogComponent} from '../../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.sass']
})
export class SupportComponent implements OnInit, OnDestroy {
    public user: IUser;
    public account: IAccount;
    public allCases;
    public cases;
    public activeCase: BehaviorSubject<any>;
    private destroyed$: Subject<{}> = new Subject();
    public showClosed;
    private newCaseRequest;
    private newCaseCommentRequest;

    constructor(private userService: UserService,
                private accountService: AccountService,
                private salesforceService: SalesforceService,
                private dialog: MatDialog,
                private snackBar: MatSnackBar) {
        this.activeCase = new BehaviorSubject(null);
        this.newCaseRequest = false;
        this.newCaseCommentRequest = false;
        this.showClosed = false;
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.newCaseRequest = false;
        this.newCaseCommentRequest = false;
    }

    public updateCases() {
        this.cases = this.allCases.filter((v) => {
            console.log(v);
            if (this.showClosed) {
                return true;
            } else {
                return v.status === 'Open';
            }
        });
    }

    public selectCase(acase) {
        this.activeCase.next(acase);
    }

    ngOnInit() {
        this.userService.user.takeUntil(this.destroyed$).subscribe((user) => {
            this.user = user;
        });
        this.accountService.activeAccount.takeUntil(this.destroyed$).subscribe((acc) => {
            this.account = acc;
        });
        this.salesforceService.casesResponse.takeUntil(this.destroyed$).subscribe((cases) => {
            console.log(cases);
            this.allCases = cases.slice();
            this.updateCases();
            if (this.activeCase.getValue() && this.cases.length > 0) {
                this.cases.forEach((acase) => {
                    if (acase.id === this.activeCase.getValue().id) {
                        this.selectCase(acase);
                    }
                });
            }
        });

        this.salesforceService.newCase.takeUntil(this.destroyed$).subscribe((newCase) => {
            if (newCase) {
                this.snackBar.open('Case Created', null, {
                    duration: 3000
                });
                this.newCaseRequest = false;
                this.salesforceService.getCases();
            } else {
                if (this.newCaseRequest) {
                    this.snackBar.open('Failed to create', null, {
                        duration: 3000
                    });
                    this.newCaseRequest = false;
                }

            }
        });

        this.salesforceService.newCaseComment.takeUntil(this.destroyed$).subscribe((newCaseComment) => {
            if (newCaseComment) {
                this.snackBar.open('Comment Added', null, {
                    duration: 3000
                });
                this.newCaseCommentRequest = false;
                this.salesforceService.getCases();
            } else {
                if (this.newCaseCommentRequest) {
                    this.snackBar.open('Failed to add comment', null, {
                        duration: 3000
                    });
                    this.newCaseCommentRequest = false;
                }

            }
        });

        this.salesforceService.getCases();
    }

    newCaseComment() {
        const dialogRef = this.dialog.open(CaseCommentDialogComponent, {
            // width: '250px',
            data: {
                comment: true,
                description: '',
                caseId: this.activeCase.getValue().id,
                email: this.user.email
            },
            width: '40vw',
            disableClose: false,
            closeOnNavigation: true,
            hasBackdrop: true,
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result) {
                this.newCaseCommentRequest = true;
                this.salesforceService.addComment({
                    comment: result.comment,
                    delete: false,
                    description: result.description,
                    email: result.email,
                    caseId: result.caseId
                });
            }
        });
    }

    closeCase() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            // width: '250px',
            disableClose: false,
            closeOnNavigation: true,
            hasBackdrop: false,
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result) {
                this.newCaseRequest = true;
                this.salesforceService.closeCase({
                    delete: true,
                    comment: false,
                    caseId: this.activeCase.getValue().id
                });
            }
        });
    }

    newCase() {
        const dialogRef = this.dialog.open(NewCaseDialogComponent, {
            // width: '250px',
            data: {
                user: this.user,
                description: '',
                subject: '',
                severity: 'Level 3 - Minor',
            },
            disableClose: false,
            closeOnNavigation: true,
            hasBackdrop: true,
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result) {
                this.newCaseRequest = true;
                this.salesforceService.createCase({
                    comment: false,
                    delete: false,
                    subject: result.subject,
                    description: result.description,
                    severity: result.severity,
                    firstName: result.user.firstName,
                    lastName: result.user.lastName,
                    email: result.user.email,
                });
            }
        });
    }

}
