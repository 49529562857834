import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AccountService} from '../../../services/account.service';
import {IAccount} from '../../../interfaces/iaccount';
import {DashboardService} from '../../../services/dashboard.service';
import * as moment from 'moment';
import 'rxjs/add/operator/takeUntil';
import {Subject} from 'rxjs/Subject';
import {IAccountSettings} from '../../../interfaces/account-settings';
import {ChartErrorEvent, GoogleChartComponent} from 'ng2-google-charts';
import {DatesService} from '../../../services/dates.service';
import {TimeframePreset} from '../../../interfaces/timeframe-preset';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('columnChart') columnChart: GoogleChartComponent;
    @ViewChild('pieChart') pieChart: GoogleChartComponent;
    public account: IAccount;
    public accountSettings: IAccountSettings;

    public dashboardVideosLoading = true;
    public dashboardViewsLoading = true;
    public dashboardVisitsLoading = true;
    public dashboardClicksLoading = true;
    public dashboardRendersLoading = true;
    public dashboardDevicesLoading = true;
    public dashboardTemplatesLoading = true;
    public accountLoading = true;

    public columnChartError = false;

    // Devices
    public devicesChartLabels: string[] = [];
    public devicesChartData: [
        ['Device', 'Count'],
        ['No Data', 100]
        ];
    public devicesChartOptions: any = {
        responsive: true,

        legend: {
            position: 'left'
        }
    };

    public summaryChartData: Array<any> = [];
    public summaryChartOptions: any = {
        responsive: true,
        // maintainAspectRatio: false,
        legend: {
            position: 'top'
        }
    };
    // Credits
    public creditsGaugeType = 'arch';
    public creditsGaugeValue = 0;
    public creditsGaugeLabel = 'Credits';
    public creditsGaugeCap = 'round';
    public creditsGaugeThresholds;
    // Production
    public producedGaugeType = 'arch';
    public producedGaugeLabel = 'Videos';
    public producedGaugeCap = 'round';
    public producedGaugeValue = 0;

    public totalViews = 0;
    public totalUniqueViews = 0;

    public totalClicks = 0;
    public totalUniqueClicks = 0;

    public totalVisits = 0;
    public totalUniqueVisits = 0;

    // public totalCTAClicks = 0;
    // Templates
    public templates;
    public columnChartReady = false;
    public pieChartData = {
        chartType: 'PieChart',
        dataTable: [
            ['Device', 'Count'],
            ['Work', 11],
            ['Eat', 2],
            ['Commute', 2],
            ['Watch TV', 2],
            ['Sleep', 7]
        ],
        options: {
            // title: 'Devices',
            is3D: true,
            legend: {position: 'none', maxLines: 5},
            animation: {
                duration: 1500,
                easing: 'linear',
                startup: true
            },
        },
    };

    public columnChartData = {
        chartType: 'ColumnChart',
        dataTable: [
            [
                'Date',
                {
                    'type': 'string',
                    'role': 'tooltip',
                    'p': {'html': true}
                },
                'Visits', 'Video Views', 'CTAs'],
        ],
        // formatters: [
        //     {
        //         columns: [0],
        //         type: 'DateFormat',
        //         options: {
        //             formatType: 'long'
        //         }
        //     }
        // ],
        options: {
            colors: ['#00BCD4', '#38BD9C', '#BCC934'],
            // title: 'Summary (' + this.dateService.selectedTimePreset.period + ')',
            // titlePosition: 'out',
            allowHtml: true,
            focusTarget: 'category',
            tooltip: {isHtml: true},
            animation: {
                duration: 1500,
                easing: 'linear',
                startup: true
            },
            // chartArea: {width: '100%', height: '100%'},
            hAxis: {
                // allowContainerBoundaryTextCufoff: false,
                format: 'MMM d',
                // gridlines: {
                //     count:-1,
                // }
                // slantedText: true
            },
            legend: {
                position: 'top',
                alignment: 'center',
                maxLines: 1
            },
            bar: {groupWidth: '85%'},
            series: {
                0: {targetAxisIndex: 0},
                1: {targetAxisIndex: 1},
                2: {targetAxisIndex: 1},
            },
            vAxes: {
                // Adds titles to each axis.
                0: {title: 'Visits', gridlines: {color: '#ffffff'}},
                1: {title: 'Views & CTAs', gridlines: {color: '#ffffff'}},

            },
            // isStacked: true,
        },
    };
    private destroyed$: Subject<{}> = new Subject();
    private player;

    constructor(private dashboardService: DashboardService,
                private accountService: AccountService,
                public dateService: DatesService) {
        // this.changeTimePreset(this.dateService.selectedTimePreset);
    }

    public columnError(event: ChartErrorEvent) {
        this.columnChartError = true;
        // console.log(event);
        // this.columnChartReady = false;
        // this.getChartLabels(this.dateService.selectedTimePreset.start, this.dateService.selectedTimePreset.end).forEach((v) => {
        //     this.columnChartData.dataTable.push([v, this.getColumnTableTooltip(v,0,0,0), 0, 0, 0]);
        // });
        // this.formatDateColumn();
        // this.columnChartReady = true;
    }

    public getDateForCustom(unix): Date {
        return new Date(unix * 1000);
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.columnChart) {
            this.columnChartReady = false;
            setTimeout(() => {
                this.columnChartReady = true;
            }, 300);
        }
        if (this.pieChart) {
            this.dashboardDevicesLoading = true;
            setTimeout(() => {
                this.dashboardDevicesLoading = false;
            }, 300);
        }
    }

    public changeTimePreset(preset) {
        this.dateService.changeTimePreset(preset)
            .then(() => {
                if (this.dateService.selectedTimePreset.name !== 'Custom') {
                    this.getDashboardCompsNew(this.dateService.selectedTimePreset);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    public customStart(event) {
        this.dateService.setCustomStart(event);
    }

    public customEnd(event) {
        this.dateService.setCustomEnd(event)
            .then(() => {
                // this.getDashboardCompsNew(this.dateService.selectedTimePreset);
            })
            .catch(console.error);
    }

    public go() {
        this.getDashboardCompsNew(this.dateService.selectedTimePreset);
    }

    public chartClicked(e: any): void {

    }

    public chartHovered(e: any): void {

    }

    public getPrecentOf(of, from): string {
        return from > 0 ? (of / from * 100).toFixed(1) + ' %' : '0 %';
    }

    public getPrecentOfGauge(of, from) {
        return from > 0 ? parseFloat((of / from * 100).toFixed(2)) : 0;
    }

    ngOnInit() {
        this.accountService.loading.takeUntil(this.destroyed$).subscribe((isLoading) => {
            this.accountLoading = isLoading;
        });

        this.accountService.accountSettings.takeUntil(this.destroyed$).subscribe((accSettings) => {
            this.accountSettings = accSettings;
        });

        this.accountService.getAccountSettings();

        this.dashboardService.loadingTemplates.takeUntil(this.destroyed$).subscribe((isLoading) => {
            this.dashboardTemplatesLoading = isLoading;
        });

        this.dashboardService.playerInjected.takeUntil(this.destroyed$).subscribe((isInjected) => {
            this.player = isInjected;
        });

        this.dashboardService.loadingPageViews.takeUntil(this.destroyed$).subscribe((isLoading) => {
            this.dashboardVisitsLoading = isLoading;
            if (!isLoading) {
                this.updateSummaryChart();
            }
        });
        this.dashboardService.loadingCta.takeUntil(this.destroyed$).subscribe((isLoading) => {
            this.dashboardClicksLoading = isLoading;
            if (!isLoading) {
                this.updateSummaryChart();
            }
        });

        this.dashboardService.loadingViews.takeUntil(this.destroyed$).subscribe((isLoading) => {
            this.dashboardViewsLoading = isLoading;
            if (!isLoading) {
                this.updateSummaryChart();
            }
        });
        this.dashboardService.loadingRenders.takeUntil(this.destroyed$).subscribe((isLoading) => {
            this.dashboardRendersLoading = isLoading;
            if (!isLoading) {
                this.updateSummaryChart();
            }
        });
        this.dashboardService.loadingDevices.takeUntil(this.destroyed$).subscribe((isLoading) => {
            this.dashboardDevicesLoading = isLoading;
        });
        this.dashboardService.loadingVideos.takeUntil(this.destroyed$).subscribe((isLoading) => {
            this.dashboardVideosLoading = isLoading;
        });

        this.dashboardService.devicesResponse.takeUntil(this.destroyed$).subscribe((resp: any) => {
            this.devicesChartData = resp;
            console.log(this.devicesChartData);
        });

        this.dashboardService.templatesResponse.takeUntil(this.destroyed$).subscribe((resp: any) => {
            this.templates = resp.map((template) => {
                const sku = template.sku.replace(/\s/g, '%20');
                return {
                    name: template.name,
                    sku: sku,
                    url: '//api.treepodia.com/video/get/' + this.account.uuid + '/' + sku + '/' + template.name
                };
            });
            const playerConfig = this.templates.map((v) => {
                return {
                    account: this.account.uuid,
                    sku: v.sku,
                    width: 1280,
                    height: 720,
                    container: v.sku,
                    showTime: false,
                    popup: false,
                    showPoster: true,
                    showJumboPlay: true,
                    autoplay: false,
                    responsive: true
                };
            });
            (<any>window).initTreepodia = function (player) {
                player.show(playerConfig);
            };
            this.dashboardService.injectPlayer();
        });

        this.dashboardService.viewsResponse.takeUntil(this.destroyed$).subscribe((resp: any) => {

            this.animateCounter('totalViews', 2000, resp[0].data.reduce((acc, val) => {
                return acc + val;
            }, 0));

            this.animateCounter('totalUniqueViews', 2000, resp[1].data.reduce((acc, val) => {
                return acc + val;
            }, 0));

            this.columnChartData.dataTable.forEach((v) => {
                resp[2].data.forEach((vv) => {
                    if (v[0] === vv[0]) {
                        v[3] = vv[1];
                        v[1] = this.getColumnTableTooltip(v[0], v[2], v[3], v[4]);
                    }
                });
            });

        });

        this.dashboardService.ctaResponse.takeUntil(this.destroyed$).subscribe((resp: any) => {
            this.animateCounter('totalClicks', 2000, resp[0].data.reduce((acc, val) => {
                return acc + val;
            }, 0));

            this.animateCounter('totalUniqueClicks', 2000, resp[1].data.reduce((acc, val) => {
                return acc + val;
            }, 0));

            this.columnChartData.dataTable.forEach((v) => {
                resp[2].data.forEach((vv) => {

                    // const d: any = v[0];
                    if (v[0] === vv[0]) {
                        v[4] = vv[1];
                        v[1] = this.getColumnTableTooltip(v[0], v[2], v[3], v[4]);

                    }
                });
            });
        });

        this.dashboardService.pageViewsResponse.takeUntil(this.destroyed$).subscribe((resp: any) => {
            console.log('PAGEV', resp);
            this.animateCounter('totalVisits', 2000, resp[0].data.reduce((acc, val) => {
                return acc + val;
            }, 0));

            this.animateCounter('totalUniqueVisits', 2000, resp[1].data.reduce((acc, val) => {
                return acc + val;
            }, 0));

            this.columnChartData.dataTable.forEach((v) => {
                resp[2].data.forEach((vv) => {

                    if (v[0] === vv[0]) {
                        v[2] = vv[1];
                        v[1] = this.getColumnTableTooltip(v[0], v[2], v[3], v[4]);

                    }
                });
            });
        });

        this.dashboardService.rendersResponse.takeUntil(this.destroyed$).subscribe((resp: any) => {
            this.animateCounter('producedGaugeValue', 2000, resp[0].data.reduce((acc, val) => {
                return acc + val;
            }, 0));
        });

        this.dashboardService.videosResponse.takeUntil(this.destroyed$).subscribe((resp: any) => {
            this.producedGaugeValue = resp;
        });

        this.accountService.activeAccount.takeUntil(this.destroyed$).subscribe((activeAccount) => {
            if (activeAccount != null) {
                this.creditsGaugeThresholds = this.calcThreshold(activeAccount.globalCredits);
                const credits = activeAccount.globalCredits - activeAccount.totalVideos;
                this.creditsGaugeValue = credits > 0 ? credits : 0;
                this.dateService.setCustomMin(new Date(activeAccount.lastEnabledDate));
                this.dateService.setCustomMax(new Date());
                this.getDashboardCompsNew(this.dateService.selectedTimePreset);
            }
            this.account = activeAccount;
        });
    }

    //

    ngAfterViewInit(): void {

    }

    public isLoading() {
        return this.dashboardVideosLoading
            || this.dashboardViewsLoading
            || this.dashboardRendersLoading
            || this.dashboardDevicesLoading;
    }

    private getColumnTableTooltip(date, clicks, views, ctas): string {
        const formatData = new Date(date.replace(' 00:00:00 UTC', '')).toDateString();
        console.log(formatData);
        return '<div class="roboto" style="padding:10px;">' +
            '<p style="border-bottom: 1px solid #323232; text-align: center; font-weight: bold">' + formatData + '</p>' +
            '<p style="color: #00BCD4"><span style="font-weight: 500">Visits:</span>&nbsp;&nbsp;' + clicks + '</p>' +
            '<p style="color: #38BD9C"><span style="font-weight: 500">Views&nbsp;:</span>&nbsp;&nbsp;' + views + '</p>' +
            '<p style="color: #BCC934"><span style="font-weight: 500">CTAs&nbsp;&nbsp;:</span>&nbsp;&nbsp;' + ctas + '</p>' +
            '</div>';
    }

    private getDashboardCompsNew(preset: TimeframePreset) {
        this.columnChartReady = false;
        this.totalVisits = 0;
        this.totalViews = 0;
        this.totalClicks = 0;
        this.producedGaugeValue = 0;
        this.columnChartData.dataTable = [
            [
                'Date',
                {
                    'type': 'string',
                    'role': 'tooltip',
                    'p': {'html': true}
                },
                'Visits', 'Video Views', 'CTAs'],
        ];
        this.getChartLabels(preset.start, preset.end).forEach((v) => {
            this.columnChartData.dataTable.push([v, '', 0, 0, 0]);

        });
        this.dashboardService.get(preset.startUtc, preset.endUtc);
    }


    private updateSummaryChart() {
        if (!this.dashboardVisitsLoading && !this.dashboardViewsLoading &&
            !this.dashboardClicksLoading && !this.dashboardRendersLoading) {
            for (let i = 1; i < this.columnChartData.dataTable.length; i++) {
                if (this.columnChartData.dataTable[i][2] == '0'
                    && this.columnChartData.dataTable[i][3] == '0'
                    && this.columnChartData.dataTable[i][4] == '0') {
                    this.columnChartData.dataTable.splice(i--, 1);

                }
            }
            this.formatDateColumn();
            this.columnChartError = false;
            this.columnChartReady = true;
        }
    }

    private formatDateColumn() {
        for (let i = 1; i < this.columnChartData.dataTable.length; i++) {
            const d: any = this.columnChartData.dataTable[i][0];
            console.log()
            this.columnChartData.dataTable[i][0] = moment(new Date(d.replace(' 00:00:00 UTC', ''))).format('MMM DD');
        }
    }

    private getChartLabels(startUnix, endUnix) {
        const diff = moment.unix(endUnix).diff(moment.unix(startUnix), 'days');
        const res = [];
        for (let i = 0; i <= diff; i++) {
            res.push(moment.unix(startUnix).add(i, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss UTC'));
        }

        return res;
    }

    private calcThreshold(credits): {} {
        const green = (credits * 0.6) + '';
        const orange = (credits * 0.3) + '';
        const res = {};
        res['0'] = {color: 'red'};
        res[orange] = {color: 'orange'};
        res[green] = {color: 'green'};
        return res;
    }

    private animateCounter(counter, duration, val, init?) {
        let animInterval;
        const step = (val / (duration / 100));
        if (init && init < val) {
            this[counter] = init;
        }
        if (this[counter] < val) {

            animInterval = setInterval(() => {
                if (!checkStop()) {
                    this[counter] = Math.ceil(this[counter] + step);
                }
            }, 100);


        }

        const checkStop = function () {
            if (this[counter] === val || (this[counter] + step) > val) {
                this[counter] = val;
                clearInterval(animInterval);
            }
            return this[counter] === val;
        }.bind(this);
    }

}
