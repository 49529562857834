import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.sass']
})
export class ProgressBarComponent implements OnInit {
    @Input() value: number;
    @Input() max: number;
    @Input() height: number;
    @Input() bgColor: string;
    @Input() color: string;
    @Input() down: boolean;

    constructor() {

    }

    public getStyles() {
        return {
            'line-height': this.height ? this.height + 'px' : '30px',
            'font-size': this.height ? (this.height - 5) + 'px' : '30px',
        };
    }

    public getWidth() {
        return {
            'width': (this.value / this.max * 100) + '%',
            'background-color': this.getBackground()
        };
    }

    private getBackground() {
        if ((this.value / this.max * 100) > 65) {
            return 'rgba(57, 191, 57, 0.83)';
        } else if ((this.value / this.max * 100) > 40) {
            return 'rgba(253, 106, 0, 0.77)';
        } else {
            return 'rgba(245, 0, 21, 0.8)';
        }
    }

    ngOnInit() {
    }

}
