import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-dal-query',
    templateUrl: './dal-query.component.html',
    styleUrls: ['./dal-query.component.sass']
})
export class DalQueryComponent implements OnInit {
    public loading;

    constructor(private api: ApiService) {
        this.api.loading.subscribe((isLoading) => {
            this.loading = isLoading;
        });
    }

    ngOnInit() {
    }

}
