import {Injectable} from '@angular/core';
import {IUser} from '../interfaces/iuser';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ApiService} from './api.service';

@Injectable()
export class UserService {
    public user = new BehaviorSubject<IUser>(null);

    constructor(private api: ApiService) {
    }

    public getUserDetails() {
        this.api.getRequest('/user').subscribe((userData: any) => {
            if (userData.success) {
                this.user.next(userData.user);
            }
        });
    }
}
