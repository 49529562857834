import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-credits-card',
    templateUrl: './credits-card.component.html',
    styleUrls: ['./credits-card.component.sass']
})
export class CreditsCardComponent implements OnInit {
    @Input() value: number;
    @Input() title: string;
    @Input() content: string;


    @Input() bgColor: string;
    @Input() fontColor: string;

    @Input() gaugeBgColor: string;
    @Input() gaugeFrontColor: string;

    constructor() {
    }

    ngOnInit() {
        if (!this.value) {
            this.value = 0;
        }
        if (!this.title) {
            this.title = '';
        }
        if (!this.content) {
            this.content = '';
        }
    }

    public getGaugeStyles() {
        return {};
    }

    public getCardStyles() {
        // style="padding:0;background-color: #00BCD4; color: #ffffff"
        return {
            'padding': '10px',
            'background-color': this.bgColor || '#ffffff',
            'color': this.fontColor || '#000000'
        };
    }

}
