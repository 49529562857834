import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {ApiService} from './services/api.service';
import {MatSidenav} from '@angular/material';
import {AccountService} from './services/account.service';
import {IAccount} from './interfaces/iaccount';
import {AuthService} from './services/auth.service';
import {UserService} from './services/user.service';
import {IUser} from './interfaces/iuser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnDestroy, OnInit {
    @ViewChild(MatSidenav) snav: MatSidenav;
    public mobileQuery: MediaQueryList;
    public navPages = [
        {title: 'Dashboard', path: ''},
        {title: 'Performance Report', path: 'report'},
        // {title: 'VOD Request', path: 'vod'},
        {title: 'Support', path: 'support'},
    ];
    public loading: boolean;
    public isInSession: boolean;
    public account: IAccount;
    public availableAccounts: IAccount[];
    public user: IUser;
    private _mobileQueryListener: () => void;

    constructor(changeDetectorRef: ChangeDetectorRef,
                media: MediaMatcher,
                private api: ApiService,
                private auth: AuthService,
                private accService: AccountService,
                private userService: UserService) {

        if (location.protocol === 'http:') {
            location.href = location.href.replace(/^http:/, 'https:');
        }

        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnInit(): void {
        this.api.loading.subscribe((isLoading) => {
            this.loading = isLoading;
        });
        this.accService.availableAccounts.subscribe((activeAccount) => {
            this.availableAccounts = activeAccount;
        });
        this.accService.activeAccount.subscribe((activeAccount) => {
            this.account = activeAccount;
        });
        this.auth.isInSession.subscribe((isSession) => {
            if (isSession) {
                // this.accService.getAccountInfo();
            }
            this.isInSession = isSession;
        });
        this.userService.user.subscribe((user) => {
            // console.log(user);
            this.user = user;
            if (user && user.superUser) {
                this.navPages.push({title: 'Settings', path: 'settings'});
            }
        });
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    logOut() {
        this.auth.logOut();
    }

    public changeAccount() {
        this.accService.selectAccount();
    }


}
