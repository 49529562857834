import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-template-sample',
    templateUrl: './template-sample.component.html',
    styleUrls: ['./template-sample.component.sass']
})
export class TemplateSampleComponent implements OnInit {
    @Input() title: string;
    @Input() content: string;
    @Input() sku: string;
    @Input() uuid: string;

    @Input() bgColor: string;
    @Input() fontColor: string;

    public videoUrl;
    public videoPoster;
    public colors = [
        '#607D8B',
        '#FF9800',
        '#38BD9C',
        '#d22aca'
    ];

    constructor() {
    }

    ngOnInit() {
        this.videoUrl = '//api.treepodia.com/video/get/' + this.uuid + '/' + this.sku;
        this.videoPoster = '//api.treepodia.com/image/get/' + this.uuid + '/' + this.sku;
        if (!this.bgColor) {
            this.bgColor = this.getColor()
        }
    }

    public getDivStyles() {
        return {
            padding: '10px'
        };
    }

    public getVideoStyles() {
        return {
            width: '100%'
        };
    }

    public getCardStyles() {
        // style="padding:0;background-color: #00BCD4; color: #ffffff"
        return {
            'padding': '0',
            'background-color': this.bgColor,
            'color': this.fontColor || '#000000'
        };
    }

    private getColor() {
        const c = Math.floor(Math.random() * this.colors.length);
        return this.colors[c];
    }

}
