import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {IAccount} from '../interfaces/iaccount';
import {MatDialog} from '@angular/material';
import {SelectAccountDialogComponent} from '../components/dialogs/select-account-dialog/select-account-dialog.component';
import {Subject} from 'rxjs/Subject';
import {IAccountSettings} from '../interfaces/account-settings';

@Injectable()
export class AccountService {
    public activeAccount = new BehaviorSubject<IAccount>(null);
    public availableAccounts = new BehaviorSubject<IAccount[]>(null);
    public accountSettings: Subject<IAccountSettings>;
    public loading: BehaviorSubject<boolean>;

    constructor(private api: ApiService, private dialog: MatDialog) {
        this.accountSettings = new Subject<IAccountSettings>();
        this.loading = new BehaviorSubject<boolean>(false);
    }

    public getAccountSettings() {
        this.loading.next(true);
        this.api.getRequest('/account/settings')
            .subscribe((accountSettings: any) => {
                if (accountSettings.success) {
                    this.accountSettings.next(accountSettings);
                    this.loading.next(false);
                } else {
                    this.loading.next(false);
                    console.log(accountSettings.error);
                }
            });
    }

    public setAccountSettings(settings) {
        this.loading.next(true);
        this.api.postRequest('/account/settings', {
            useCase: settings.useCase,
            ctaAction: settings.ctaAction,
            notify: settings.notify,
            email: settings.email,
            minCount: settings.minCount,
        }).subscribe((accountSettings: any) => {
            if (accountSettings.success) {
                this.accountSettings.next(accountSettings);
                this.loading.next(false);
            } else {
                this.loading.next(false);
                console.log(accountSettings.error);
            }
        });
    }

    public getAvailableAccounts() {
        this.loading.next(true);
        this.api.getRequest('/availableAccounts')
            .subscribe((availableAccounts: any) => {
                if (availableAccounts.success) {
                    this.availableAccounts.next(availableAccounts.accounts);
                    this.loading.next(false);
                    if (availableAccounts.activeAccount) {
                        this.getActiveAccount();
                    } else {
                        this.selectAccount();
                    }
                } else {
                    this.loading.next(false);
                    console.log(availableAccounts.error);
                }
            });
    }

    selectAccount(): void {
        this.activeAccount.next(null);
        if (this.availableAccounts.getValue().length === 1) {
            this.setActiveAccount(this.availableAccounts.getValue()[0]);
        } else {
            const dialogRef = this.dialog.open(SelectAccountDialogComponent, {
                // width: '250px',
                data: this.availableAccounts.getValue().sort(),
                disableClose: true,
                closeOnNavigation: false,
                hasBackdrop: false,
            });

            dialogRef.afterClosed().subscribe(result => {
                this.setActiveAccount(result);
            });
        }

    }

    private getActiveAccount() {
        this.loading.next(true);
        this.api.getRequest('/account')
            .subscribe((activeAccount: IAccount) => {
                this.activeAccount.next(activeAccount);
                this.loading.next(false);
            });
    }

    private setActiveAccount(account: IAccount) {
        // this.activeAccount.next(null);
        this.loading.next(true);
        this.api.postRequest('/account', account).subscribe((res) => {
            if (res) {
                this.getActiveAccount();
            }
        });
    }

}
