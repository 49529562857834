import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs/Subject';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.sass']
})
export class MessageComponent implements OnInit {
    @Input() message: string;
    @Input() type: number;
    private bgColor = '#ffffff';

    constructor() {
    }

    ngOnInit() {
        if (this.type == 0) {
            this.bgColor = 'rgb(228, 14, 14)';
        }
    }

    public getCardStyles() {
        // style="padding:0;background-color: #00BCD4; color: #ffffff"
        return {
            'padding': '15px',
            'background-color': this.bgColor || '#ffffff',
            'color': '#ffffff'
        };
    }

}
