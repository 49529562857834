import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './components/pages/dashboard/dashboard.component';
import {ReportComponent} from './components/pages/report/report.component';
import {VodComponent} from './components/pages/vod/vod.component';
import {SupportComponent} from './components/pages/support/support.component';
import {AccountSettingsComponent} from './components/pages/account-settings/account-settings.component';

const routes: Routes = [
    {
        path: 'report',
        component: ReportComponent,
        // canActivate: [AuthGuard]
    }, {
        path: 'vod',
        component: VodComponent,
        // canActivate: [AuthGuard]
    }, {
        path: 'support',
        component: SupportComponent,
        // canActivate: [AuthGuard]
    }, {
        path: 'settings',
        component: AccountSettingsComponent,
        // canActivate: [AuthGuard]
    },
    {
        path: '',
        component: DashboardComponent,
        // canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
