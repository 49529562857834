import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-summary-card',
    templateUrl: './summary-card.component.html',
    styleUrls: ['./summary-card.component.sass']
})
export class SummaryCardComponent implements OnInit {
    @Input() icon: string;
    @Input() title: string;
    @Input() tooltip: string;
    @Input() content: string;
    @Input() fontColor: string;
    @Input() bgColor: string;
    @Input() iconBgColor: string;
    @Input() loading: boolean;

    constructor() {
    }

    ngOnInit() {
        if (!this.icon) {
            this.icon = '';
        }
        if (!this.title) {
            this.title = '';
        }
        if (!this.content) {
            this.content = '0';
        }
        if (!this.loading) {
            this.loading = false;
        }
        console.log(this.icon);
    }

    public getCardStyles() {
        // style="padding:0;background-color: #00BCD4; color: #ffffff"
        return {
            'padding': '0',
            'background-color': this.bgColor || '#ffffff',
            'color': this.fontColor || '#000000'
        };
    }

    public getIconStyles() {
        // style="background-color: #00AABF"
        return {
            'background-color': this.iconBgColor || '#ffffff'
        };
    }

}
