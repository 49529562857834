import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/add/observable/of';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class ApiService {
    public loading = new BehaviorSubject<boolean>(false);
    private loadingStack = [];
    private SERVER_URL = '/api';
    // private SERVER_URL = '//localhost:8080/api';

    private httpOptions = {
        headers: new HttpHeaders(),
        params: new HttpParams(),
        withCredentials: true
    };

    constructor(private http: HttpClient) {

    }

    public getRequest(url, headers?, params?) {
        this.updateLoading(true);
        const res = new Subject();
        this.http.get(this.SERVER_URL + url, this.httpOptions).subscribe((response) => {
            res.next(response);
            this.updateLoading(false);
        }, (error: HttpErrorResponse) => {
            if (error.status === 401) {
                // this.authService.logOut();
            }
            this.updateLoading(false);
        });
        return res;
    }

    public postRequest(url, body, headers?, params?) {
        this.updateLoading(true);
        const res = new Subject();
        this.http.post(this.SERVER_URL + url, body, this.httpOptions).subscribe((response) => {
            res.next(response);
            this.updateLoading(false);
        }, (error: HttpErrorResponse) => {
            if (error.status === 401) {
                res.next(error);
                // this.authService.logOut();
            } else {
                res.next(error);
            }
            this.updateLoading(false);
        });
        return res;
    }

    public deleteRequest(url, headers?, params?) {
        this.updateLoading(true);
        const res = new Subject();
        this.http.delete(this.SERVER_URL + url, this.httpOptions).subscribe((response) => {
            res.next(response);
            this.updateLoading(false);
        }, (error: HttpErrorResponse) => {
            if (error.status === 401) {
                // this.authService.logOut();
            }
            this.updateLoading(false);
        });
        return res;
    }

    public updateLoading(loading: boolean) {
        if (loading) {
            this.loadingStack.push(loading);
            this.loading.next(loading);
        } else {
            this.loadingStack.pop();
            if (this.loadingStack.length === 0) {
                this.loading.next(loading);
            }
        }

    }


    // getAccountInfo() {
    //     // this.http.get('http://localhost:8080/api/account', this.getOptions).subscribe((accountInfo) => {
    //     //     console.log(accountInfo);
    //     //     this.loading.next(false);
    //     // });
    //     return this.http.get('http://localhost:8080/api/account', this.getOptions);
    // }
}
