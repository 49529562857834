import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialDesignModule} from './modules/material-design/material-design.module';
import {DashboardComponent} from './components/pages/dashboard/dashboard.component';
import {ReportComponent} from './components/pages/report/report.component';
import {VodComponent} from './components/pages/vod/vod.component';
import {SupportComponent} from './components/pages/support/support.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HttpClientModule} from '@angular/common/http';
import {ApiService} from './services/api.service';
import {LoginComponent} from './components/pages/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard} from './guards/auth.guard';
import {ChartsModule} from 'ng2-charts';
import {DalQueryComponent} from './components/dal-query/dal-query.component';
import {AccountService} from './services/account.service';
import {AlertsService} from './services/alerts.service';
import {UserService} from './services/user.service';
import {AuthService} from './services/auth.service';
import {SelectAccountDialogComponent} from './components/dialogs/select-account-dialog/select-account-dialog.component';
import {CookieService} from 'ngx-cookie-service';
import {ReportService} from './services/report.service';
import {TemplateSampleDialogComponent} from './components/dialogs/template-sample-dialog/template-sample-dialog.component';
import {NgxGaugeModule} from 'ngx-gauge';
import {DashboardService} from './services/dashboard.service';
import {MessageComponent} from './components/message/message.component';
import {PapaParseModule} from 'ngx-papaparse';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {AccountSettingsComponent} from './components/pages/account-settings/account-settings.component';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';
import {ViewVideoDialogComponent} from './components/dialogs/view-video-dialog/view-video-dialog.component';
import {NewCaseDialogComponent} from './components/dialogs/new-case-dialog/new-case-dialog.component';
import {SalesforceService} from './services/salesforce.service';
import {CaseCommentDialogComponent} from './components/dialogs/case-comment-dialog/case-comment-dialog.component';
import {ConfirmationDialogComponent} from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {DatesService} from './services/dates.service';
import {SummaryCardComponent} from './components/summary-card/summary-card.component';
import {GaugeCardComponent} from './components/gauge-card/gauge-card.component';
import {DevicesDonutComponent} from './components/devices-donut/devices-donut.component';
import {CreditsCardComponent} from './components/credits-card/credits-card.component';
import {TemplateSampleComponent} from './components/template-sample/template-sample.component';

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        ReportComponent,
        VodComponent,
        SupportComponent,
        LoginComponent,
        DalQueryComponent,
        SelectAccountDialogComponent,
        TemplateSampleDialogComponent,
        MessageComponent,
        ProgressBarComponent,
        AccountSettingsComponent,
        ViewVideoDialogComponent,
        NewCaseDialogComponent,
        CaseCommentDialogComponent,
        ConfirmationDialogComponent,
        SummaryCardComponent,
        GaugeCardComponent,
        DevicesDonutComponent,
        CreditsCardComponent,
        TemplateSampleComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        MaterialDesignModule,
        ChartsModule,
        FormsModule,
        ReactiveFormsModule,
        NgxGaugeModule,
        PapaParseModule,
        Ng2GoogleChartsModule,
    ],
    providers: [
        AuthService,
        ApiService,
        UserService,
        AccountService,
        AuthGuard,
        ReportService,
        DashboardService,
        CookieService,
        SalesforceService,
        DatesService,
        AlertsService,
    ],
    entryComponents: [
        SelectAccountDialogComponent,
        ViewVideoDialogComponent,
        NewCaseDialogComponent,
        CaseCommentDialogComponent,
        ConfirmationDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
