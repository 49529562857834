import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable()
export class AlertsService {
    public message: BehaviorSubject<string>;
    public type: BehaviorSubject<number>;
    public show: BehaviorSubject<boolean>;

    constructor() {
        this.message = new BehaviorSubject<string>("");
        this.type = new BehaviorSubject<number>(0);
        this.show = new BehaviorSubject<boolean>(false);
    }

    public publish(message, type) {
        this.message.next(message);
        this.type.next(type);
        this.show.next(true);
    }

    public hide() {
        this.message.next("");
        this.type.next(0);
        this.show.next(false);
    }
}
