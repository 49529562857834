import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class DashboardService {
    public videosResponse: Subject<any>;
    public viewsResponse: Subject<any>;
    public rendersResponse: Subject<any>;
    public devicesResponse: Subject<any>;
    public templatesResponse: Subject<any>;
    public ctaResponse: Subject<any>;
    public pageViewsResponse: Subject<any>;

    public loadingVideos: BehaviorSubject<boolean>;
    public loadingViews: BehaviorSubject<boolean>;
    public loadingRenders: BehaviorSubject<boolean>;
    public loadingDevices: BehaviorSubject<boolean>;
    public loadingTemplates: BehaviorSubject<boolean>;
    public loadingCta: BehaviorSubject<boolean>;
    public loadingPageViews: BehaviorSubject<boolean>;
    public playerInjected: BehaviorSubject<boolean>;

    constructor(private api: ApiService) {
        this.loadingVideos = new BehaviorSubject<boolean>(false);
        this.loadingViews = new BehaviorSubject<boolean>(false);
        this.loadingRenders = new BehaviorSubject<boolean>(false);
        this.loadingDevices = new BehaviorSubject<boolean>(false);
        this.loadingTemplates = new BehaviorSubject<boolean>(false);
        this.loadingCta = new BehaviorSubject<boolean>(false);
        this.loadingPageViews = new BehaviorSubject<boolean>(false);
        this.playerInjected = new BehaviorSubject<boolean>(false);
        this.videosResponse = new Subject();
        this.viewsResponse = new Subject();
        this.rendersResponse = new Subject();
        this.devicesResponse = new Subject();
        this.templatesResponse = new Subject();
        this.ctaResponse = new Subject();
        this.pageViewsResponse = new Subject();
    }

    public injectPlayer() {
        // if (this.playerInjected.getValue()) {
        //     (<any>window).TreepodiaPlayer.init();
        // } else {
        //     const script = document.createElement('script');
        //     script.src = '//dxa05szpct2ws.cloudfront.net/player3/TreepodiaPlayer-init.min.js';
        //     script.type = 'text/javascript';
        //     document.head.appendChild(script);
        //     this.playerInjected.next(true);
        // }
    }

    public get(start, end) {
        console.log(start, end);
        // console.log('getdash', this);
        this.getStats(1, start, end);
        this.getStats(3, start, end);
        this.getStats(4, start, end);
        this.getDevices(start, end);
        this.getRenders(start, end);
        this.getTemplates();
        // this.getVideos(start, end);
        // this.api.postRequest('/dashboard', {
        //     'startTimestamp': start,
        //     'endTimestamp': end
        // }).subscribe((dashboard: any) => {
        //
        //     const dash: IDashboard = {
        //         startTime: moment(start).format('YYYY-MM-DD'),
        //         endTime: moment(end).format('YYYY-MM-DD'),
        //     };
        //
        //     // this.dashboardLoading = false;
        //     // this.animateCounter(this.videosProduced, 3000, dashboard.videoProduced);
        //     console.log(dash);
        //     this.dashboard.next(dash);
        //     this.loading.next(false);
        // });
    }

    private getTemplates() {
        this.loadingTemplates.next(true);
        this.api.getRequest('/account/templates').subscribe((res: any) => {
            if (res.success) {
                this.templatesResponse.next(res.videos);
            } else {
                console.log(res.error);
            }
            this.loadingTemplates.next(false);
        });
    }

    private getVideos(start, end) {
        this.loadingVideos.next(true);
        this.api.postRequest('/dashboard/videos', {
            'startTimestamp': start,
            'endTimestamp': end
        }).subscribe((res: any) => {
            if (res.success) {
                this.videosResponse.next(res.videoProduced);
            } else {
                console.log(res.error);
            }
            this.loadingVideos.next(false);
        });
    }

    private getStats(type, start, end) {
        let endpoint = '';
        let loading = '';
        let resp = '';
        let label = '';
        switch (type) {
            case 1: // pageviews
                endpoint = '/dashboard/pageviews';
                loading = 'loadingPageViews';
                resp = 'pageViewsResponse';
                label = 'Visits';
                break;
            case 3: // cta
                endpoint = '/dashboard/cta';
                loading = 'loadingCta';
                resp = 'ctaResponse';
                label = 'Clicks';
                break;
            case 4: // video view
                endpoint = '/dashboard/views';
                loading = 'loadingViews';
                resp = 'viewsResponse';
                label = 'Views';
                break;
        }
        this[loading].next(true);
        this.api.postRequest(endpoint, {
            'startTimestamp': start,
            'endTimestamp': end
        }).subscribe((res: any) => {
            if (res.success) {
                const total = res.stats.map((v) => {
                    return v.total;
                });
                const unique = res.stats.map((v) => {
                    return v.unique;
                });
                const dataTable = res.stats.map((v) => {
                    return [v.date, v.total];
                });
                this[resp].next([
                    {
                        data: total,
                        label: label
                    }, {
                        data: unique,
                        label: 'Unique' + label
                    },
                    {
                        data: dataTable,
                        label: label
                    }]
                );
            } else {
                console.log(res.error);
            }
            this[loading].next(false);
        });
    }

    private getRenders(start, end) {
        this.loadingRenders.next(true);
        this.api.postRequest('/dashboard/renders', {
            'startTimestamp': start,
            'endTimestamp': end
        }).subscribe((res: any) => {
            if (res.success) {
                const renders = res.renders.map((v) => {
                    return v.renders;
                });
                const dataTable = res.renders.map((v) => {
                    return [v.date, v.renders];
                });
                this.rendersResponse.next(
                    [
                        {
                            data: renders,
                            label: 'Renders'
                        }, {},
                        {
                            data: dataTable,
                            label: 'Renders'
                        }
                    ]
                );
            } else {
                console.log(res.error);
            }
            this.loadingRenders.next(false);
        });
    }

    private getDevices(start, end) {
        this.loadingDevices.next(true);
        this.api.postRequest('/dashboard/devices', {
            'startTimestamp': start,
            'endTimestamp': end
        }).subscribe((res: any) => {
            const dataTable = [
                ['Device', 'Count']
            ];
            if (res.success) {
                const labels = Object.keys(res.devices);
                labels.forEach((v) => {
                    dataTable.push([v, res.devices[v]]);
                });
            } else {
                dataTable.push(['No Data', '100']);
                console.log(res.error);
            }
            this.devicesResponse.next(dataTable);
            this.loadingDevices.next(false);
        });
    }

}
