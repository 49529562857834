import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../../services/account.service';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.sass']
})
export class AccountSettingsComponent implements OnInit {
    public settings;

    constructor(private accountService: AccountService, private snackBar: MatSnackBar) {

    }

    ngOnInit() {
        this.accountService.accountSettings.subscribe((settings) => {
            if (this.settings && settings) {
                this.openSnackBar('Settings Updated');
            }
            this.settings = settings;
        });
        this.accountService.getAccountSettings();
    }

    public saveSettings() {
        console.log(this.settings);
        this.accountService.setAccountSettings(this.settings);
    }

    private openSnackBar(message: string) {
        this.snackBar.open(message, null, {
            duration: 2000,
        });
    }

}
