import {Component, Inject, OnInit} from '@angular/core';
import {SelectAccountDialogComponent} from '../select-account-dialog/select-account-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-view-video-dialog',
    templateUrl: './view-video-dialog.component.html',
    styleUrls: ['./view-video-dialog.component.sass']
})
export class ViewVideoDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<SelectAccountDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
