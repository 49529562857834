import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable()
export class SalesforceService {
    public casesResponse: Subject<any>;
    public casesLoading: BehaviorSubject<any>;
    public newCase: BehaviorSubject<any>;
    public newCaseComment: BehaviorSubject<any>;

    constructor(private api: ApiService) {
        this.casesResponse = new Subject();
        this.casesLoading = new BehaviorSubject<any>(false);
        this.newCase = new BehaviorSubject<any>(false);
        this.newCaseComment = new BehaviorSubject<any>(false);
    }

    public getCases() {
        this.casesLoading.next(true);
        this.api.getRequest('/support/cases').subscribe((res: any) => {
            if (res.success) {
                this.casesResponse.next(res.cases);
            } else {
                console.log(res.error);
            }
            this.casesLoading.next(false);
        });
    }

    public addComment(newComment) {
        this.api.postRequest('/support/cases', newComment).subscribe((res: any) => {
            if (res.success) {
                this.newCaseComment.next(true);
            } else {
                this.newCaseComment.next(false);
                console.log(res.error);
            }
        });
    }

    public createCase(newCase) {
        this.api.postRequest('/support/cases', newCase).subscribe((res: any) => {
            if (res.success) {
                this.newCase.next(true);
            } else {
                this.newCase.next(false);
                console.log(res.error);

            }
        });
    }

    public closeCase(deleteCase) {
        console.log(deleteCase);
        this.api.postRequest('/support/cases', deleteCase).subscribe((res: any) => {
            if (res.success) {
                this.newCase.next(true);
            } else {
                this.newCase.next(false);
                console.log(res.error);

            }
        });
    }

}
