import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-gauge-card',
    templateUrl: './gauge-card.component.html',
    styleUrls: ['./gauge-card.component.sass']
})
export class GaugeCardComponent implements OnInit {
    @Input() size: number;
    @Input() type: string;
    @Input() min: number;
    @Input() max: number;
    @Input() value: number;
    @Input() thick: number;
    @Input() label: string;
    @Input() append: string;

    @Input() title: string;
    @Input() tooltip: string;
    @Input() content: string;

    @Input() fontColor: string;
    @Input() bgColor: string;
    @Input() loading: boolean;

    constructor() {
    }

    ngOnInit() {
        if (!this.size) {
            this.size = 200;
        }
        if (!this.type) {
            this.type = 'full';
        }
        if (!this.min) {
            this.min = 0;
        }
        if (!this.max) {
            this.max = 100;
        }
        if (!this.value) {
            this.value = 0;
        }
        if (!this.thick) {
            this.thick = 6;
        }
        if (!this.label) {
            this.label = '';
        }
        if (!this.append) {
            this.append = '';
        }
    }

    public getCardStyles() {
        // style="padding:0;background-color: #00BCD4; color: #ffffff"
        return {
            'padding': '10px',
            'background-color': this.bgColor || '#ffffff',
            'color': this.fontColor || '#000000'
        };
    }

    public getDivStyles() {
        // style="background-color: #00AABF"
        return {
            'margin': '0 auto',
            'width': this.size + 'px',
            'padding': '0 30px 5px 30px',
            'text-align': 'center'
        };
    }

}
