import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {SelectAccountDialogComponent} from '../select-account-dialog/select-account-dialog.component';

@Component({
    selector: 'app-new-case-dialog',
    templateUrl: './new-case-dialog.component.html',
    styleUrls: ['./new-case-dialog.component.sass']
})
export class NewCaseDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<SelectAccountDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(data) {
        this.dialogRef.close(data);
    }
}
