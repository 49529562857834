import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-devices-donut',
    templateUrl: './devices-donut.component.html',
    styleUrls: ['./devices-donut.component.sass']
})
export class DevicesDonutComponent implements OnInit, OnChanges {
    @ViewChild('cchart') cchart;
    @Input() title: string;
    @Input() tooltip: string;
    @Input() content: string;
    @Input() size: number;
    @Input() fontColor: string;
    @Input() bgColor: string;
    @Input() loading: boolean;
    @Input() data: object;
    public devicesDonut: any;

    public ready = true;

    constructor() {
        // console.log(this.data);

    }

    redrawChart() {
        // let googleChartWrapper = this.cchart.wrapper;
        //
        // //force a redraw
        // this.cchart.draw();
        this.ready = false;
        setTimeout(() => {
            this.ready = true;
        }, 100);
    }

    ngOnChanges() {
        if (this.data != null) {
            console.log(this.cchart);
            this.devicesDonut.dataTable = this.data;
            console.log(this.devicesDonut);
            this.redrawChart();
        }

    }

    ngOnInit() {
        if (!this.size) {
            this.size = 200;
        }
        if (!this.title) {
            this.title = '';
        }
        if (!this.content) {
            this.content = '';
        }
        if (!this.data) {
            this.data = [['Device', 'Count'], ['No Data', 100]];
        }
        console.log(this.data);
        this.devicesDonut = {
            chartType: 'PieChart',
            dataTable: this.data,
            options: {
                pieHole: 0.6,
                legend: {position: 'none', maxLines: 5},
                chartArea: {
                    width: '80%', height: '80%',
                    backgroundColor: this.bgColor,
                },
                pieSliceBorderColor: this.bgColor,
                pieSliceText: 'none',
                backgroundColor: this.bgColor,
                colors: [
                    '#FFFFFF',
                    '#f0f0f0',
                    '#e1e1e1',
                    '#d2d2d2',
                    '#c3c3c3',
                    '#b4b4b4',
                    // '#FFFFFF',
                    // '#FFFFFF',
                    // '#FFFFFF',
                    // '#FFFFFF'
                ],
                // slices: [
                //     {offset: 0.02},
                //     {offset: 0.02},
                //     {offset: 0.02},
                //     {offset: 0.02},
                //     {offset: 0.02},
                //     {offset: 0.02},
                // ],
                animation: {
                    duration: 1500,
                    easing: 'linear',
                    startup: true
                },
            },
        };
    }

    public getCardStyles() {
        // style="padding:0;background-color: #00BCD4; color: #ffffff"
        return {
            'padding': '10px',
            'background-color': this.bgColor || '#ffffff',
            'color': this.fontColor || '#000000'
        };
    }

    public getDivStyles() {
        // style="background-color: #00AABF"
        return {
            'margin': '0 auto',
            'width': this.size + 'px',
            'padding': '0 30px 5px 30px',
            'text-align': 'center'
        };
    }

}
