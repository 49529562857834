import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {IReportData} from '../../../interfaces/ireport-data';
import {ReportService} from '../../../services/report.service';
import {IAccount} from '../../../interfaces/iaccount';
import {AccountService} from '../../../services/account.service';
import {PapaParseService} from 'ngx-papaparse';
import {saveAs} from 'file-saver';
import {Subject} from 'rxjs/Subject';
import 'rxjs/add/operator/takeUntil';
import {ViewVideoDialogComponent} from '../../dialogs/view-video-dialog/view-video-dialog.component';
import {DatesService} from '../../../services/dates.service';
import {TimeframePreset} from '../../../interfaces/timeframe-preset';
import {AlertsService} from '../../../services/alerts.service';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.sass']
})

export class ReportComponent implements OnInit, OnDestroy, AfterViewInit {
    public account: IAccount;
    public displayedColumns = [
        'play',
        'sku',
        'name',
        'page_viewed',
        'video_viewed',
        'view_25',
        'view_50',
        'view_75',
        'view_100',
        'cta_clicked',
        'page_views',
        'video_views',
        'cta_clicks',
        'last_viewed'
    ];
    public dataSource: MatTableDataSource<IReportData>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public isLoadingResults = true;
    public totals: any = {};
    private destroyed$: Subject<{}> = new Subject();

    public showAlert: boolean;
    public alertMessage: string;
    public alertType: number;

    // const data: IReportData[] = [
    constructor(private reportService: ReportService,
                private accountService: AccountService,
                private papa: PapaParseService,
                public dateService: DatesService,
                private dialog: MatDialog,
                private alerts: AlertsService) {

        this.dataSource = new MatTableDataSource();
        this.totals = {
            clicked_count: 0,
            viewed_count: 0,
            c25_count: 0,
            c50_count: 0,
            c75_count: 0,
            c100_count: 0,
            cta_count: 0,
            clicks_count: 0,
            views_count: 0,
            ctas_count: 0,
        };

        // this.changeTimePreset(this.dateService.selectedTimePreset);

    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.alerts.hide();
    }

    ngOnInit() {
        this.alerts.message.takeUntil(this.destroyed$).subscribe((message) => {
            this.alertMessage = message;
        });
        this.alerts.show.takeUntil(this.destroyed$).subscribe((show) => {
            this.showAlert = show;
        });
        this.alerts.type.takeUntil(this.destroyed$).subscribe((type) => {
            this.alertType = type;
        });
        this.reportService.data.takeUntil(this.destroyed$).subscribe((reportData) => {
            this.dataSource.data = reportData;
            reportData.forEach((v) => {
                if (v.cta_clicked) {
                    this.totals.cta_count++;
                }
                this.totals.ctas_count += v.cta_clicks;
                this.totals.clicks_count += v.page_views;
                this.totals.views_count += v.video_views;

                if (v.page_viewed) {
                    this.totals.clicked_count++;
                }
                if (v.video_viewed) {
                    this.totals.viewed_count++;
                }
                if (v.view_25) {
                    this.totals.c25_count++;
                }
                if (v.view_50) {
                    this.totals.c50_count++;
                }
                if (v.view_75) {
                    this.totals.c75_count++;
                }
                if (v.view_100) {
                    this.totals.c100_count++;
                }
            });
            // console.log(reportData);
        });

        this.reportService.loadingData.takeUntil(this.destroyed$).subscribe((loading) => {
            this.isLoadingResults = loading;
            if (loading) {
                this.totals = {
                    clicked_count: 0,
                    viewed_count: 0,
                    c25_count: 0,
                    c50_count: 0,
                    c75_count: 0,
                    c100_count: 0,
                    cta_count: 0,
                    clicks_count: 0,
                    views_count: 0,
                    ctas_count: 0,
                };
            }
        });

        this.accountService.activeAccount.takeUntil(this.destroyed$).subscribe((activeAccount) => {
            if (activeAccount != null) {
                this.dateService.setCustomMin(new Date(activeAccount.lastEnabledDate));
                this.dateService.setCustomMax(new Date());
                this.getReportData(this.dateService.selectedTimePreset);
            }
            this.account = activeAccount;
            // this.accountLoading = false;
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public getDateForCustom(unix) {
        return new Date(unix * 1000);
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    public downloadReport() {
        this.reportService.loadingData.next(true);
        const data = this.papa.unparse(this.dataSource.data);
        const blob = new Blob([data], {type: 'text/plain;charset=utf-8'});
        saveAs(blob, this.account.uuid + '_' + this.dateService.selectedTimePreset.period + '_treepodia_events.csv');
        console.log(data, blob);
        this.reportService.loadingData.next(false);
    }

    public changeTimePreset(preset) {
        this.dateService.changeTimePreset(preset)
            .then(() => {
                if (this.dateService.selectedTimePreset.name !== 'Custom') {
                    this.getReportData(this.dateService.selectedTimePreset);
                }
            })
            .catch((e) => {
                console.error(e);
            });

    }

    public customStart(event) {
        this.dateService.setCustomStart(event);
    }

    public customEnd(event) {
        this.dateService.setCustomEnd(event)
            .then(() => {
                // this.getReportData(this.dateService.selectedTimePreset);
            })
            .catch(console.error);
    }

    public go() {
        this.getReportData(this.dateService.selectedTimePreset);
    }

    public isLoading() {
        return this.isLoadingResults;
    }


    public openViewDialog(sku) {
        console.log(sku);
        const dialogRef = this.dialog.open(ViewVideoDialogComponent, {
            // width: '250px',
            data: {src: '//api.treepodia.com/video/get/' + this.account.uuid + '/' + sku},
            disableClose: false,
            closeOnNavigation: true,
            hasBackdrop: true,
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    private getReportData(preset: TimeframePreset) {
        console.log(preset);
        this.reportService.get(preset.startUtc, preset.endUtc);
    }
}
