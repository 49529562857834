import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subject} from 'rxjs/Subject';
import {AlertsService} from "./alerts.service";

@Injectable()
export class ReportService {
    public data: Subject<any>;
    public loadingData: BehaviorSubject<boolean>;

    constructor(private api: ApiService, private alerts: AlertsService) {
        this.loadingData = new BehaviorSubject<boolean>(false);
        this.data = new Subject();
    }

    get(start, end) {
        this.loadingData.next(true);
        this.api.postRequest('/report', {
            'startTimestamp': start,
            'endTimestamp': end
        }).subscribe((res: any) => {
            if (res.success) {
                this.data.next(res.data);
            } else {
                console.log(res.error);
                this.alerts.publish("Performace Report is not available. Please contact support", 0);
            }
            this.loadingData.next(false);
        });
    }

}
