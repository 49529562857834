import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {CookieService} from 'ngx-cookie-service';
import {TimeframePreset} from '../interfaces/timeframe-preset';

@Injectable()
export class DatesService {

    public selectedTimePreset: TimeframePreset;

    public timeFramePresets: TimeframePreset[] = [
        {
            name: 'Today',
            start: moment().utc().unix(),
            end: moment().utc().unix(),
            startUtc: this.getUtcStartString(moment().utc().unix()),
            endUtc: this.getUtcEndString(moment().utc().unix()),
            period: this.getPeriodString(moment().utc().unix(), moment().utc().unix())
        },
        {
            name: 'Yesterday',
            start: moment().subtract(1, 'days').utc().unix(),
            end: moment().subtract(1, 'days').utc().unix(),
            startUtc: this.getUtcStartString(moment().subtract(1, 'days').utc().unix()),
            endUtc: this.getUtcEndString(moment().subtract(1, 'days').utc().unix()),
            period: this.getPeriodString(moment().subtract(1, 'days').utc().unix(), moment().subtract(1, 'days').utc().unix())
        },
        {
            name: 'This Month',
            start: moment().startOf('month').utc().unix(),
            end: moment().endOf('month').utc().unix(),
            startUtc: this.getUtcStartString(moment().startOf('month').utc().unix()),
            endUtc: this.getUtcEndString(moment().endOf('month').utc().unix()),
            period: this.getPeriodString(moment().startOf('month').utc().unix(), moment().endOf('month').utc().unix())
        },

        {
            name: 'Last Month',
            start: moment().subtract(1, 'months').startOf('month').utc().unix(),
            end: moment().subtract(1, 'months').endOf('month').utc().unix(),
            startUtc: this.getUtcStartString(moment().subtract(1, 'months').startOf('month').utc().unix()),
            endUtc: this.getUtcEndString(moment().subtract(1, 'months').endOf('month').utc().unix()),
            period: this.getPeriodString(moment().subtract(1, 'months').startOf('month').utc().unix(),
                moment().subtract(1, 'months').endOf('month').utc().unix())
        },
        {
            name: 'Last 7 Days',
            start: moment().subtract(6, 'days').utc().unix(),
            end: moment().utc().unix(),
            startUtc: this.getUtcStartString(moment().subtract(6, 'days').utc().unix()),
            endUtc: this.getUtcEndString(moment().utc().unix()),
            period: this.getPeriodString(moment().subtract(6, 'days').utc().unix(), moment().utc().unix())
        },
        {
            name: 'Last 30 Days',
            start: moment().subtract(29, 'days').utc().unix(),
            end: moment().utc().unix(),
            startUtc: this.getUtcStartString(moment().subtract(29, 'days').utc().unix()),
            endUtc: this.getUtcEndString(moment().utc().unix()),
            period: this.getPeriodString(moment().subtract(29, 'days').utc().unix(), moment().utc().unix())
        },
        {
            name: 'Custom',
            start: moment().utc().unix(),
            end: moment().utc().unix(),
            min: new Date(),
            max: new Date(),
            prev: new Date(),
            startUtc: this.getUtcStartString(moment().utc().unix()),
            endUtc: this.getUtcEndString(moment().utc().unix()),
            period: this.getPeriodString(moment().utc().unix(), moment().utc().unix())
        }
    ];

    constructor(private cookieService: CookieService) {
        if (this.cookieService.check('selectedTimePreset')) {
            if (JSON.parse(cookieService.get('selectedTimePreset')).name === 'Custom') {
                this.timeFramePresets[6] = JSON.parse(cookieService.get('selectedTimePreset'));
            }
            this.selectedTimePreset = this.timeFramePresets.filter((preset) => {
                return preset.name === JSON.parse(cookieService.get('selectedTimePreset')).name;
            })[0];
            // console.log(this.selectedTimePreset);
        } else {
            this.selectedTimePreset = this.timeFramePresets[4];
        }
    }

    public changeTimePreset(preset) {
        return new Promise((resolve, reject) => {
            try {
                this.selectedTimePreset = preset;
                this.setSelectedTimePresetCookie();
                resolve();
            } catch (e) {
                reject(e);
            }
        });

    }

    public setCustomStart(timestamp) {
        this.selectedTimePreset.prev = new Date(timestamp.value);
        this.selectedTimePreset.start = moment(timestamp.value).unix();
        this.selectedTimePreset.startUtc = this.getUtcStartString(this.selectedTimePreset.start);
        this.selectedTimePreset.period = this.getPeriodString(this.selectedTimePreset.start, this.selectedTimePreset.end);
        this.setSelectedTimePresetCookie();
    }

    public setCustomEnd(timestamp) {
        return new Promise((resolve, reject) => {
            try {
                this.selectedTimePreset.end = moment(timestamp.value).unix();
                this.selectedTimePreset.endUtc = this.getUtcEndString(this.selectedTimePreset.end);
                this.selectedTimePreset.period = this.getPeriodString(this.selectedTimePreset.start, this.selectedTimePreset.end);
                this.setSelectedTimePresetCookie();
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }

    public setCustomMin(date) {
        this.timeFramePresets[6].min = date;
    }

    public setCustomMax(date) {
        this.timeFramePresets[6].max = date;
    }

    private getUtcStartString(unixTime) {
        return moment.unix(unixTime).startOf('day').format('YYYY-MM-DD HH:mm:ss UTC');
    }

    private getUtcEndString(unixTime) {
        return moment.unix(unixTime).endOf('day').format('YYYY-MM-DD HH:mm:ss UTC');
    }

    private getPeriodString(startUnix, endUnix) {
        return moment.unix(startUnix).format('MMM DD, YYYY') + ' - ' + moment.unix(endUnix).format('MMM DD, YYYY');
    }

    private setSelectedTimePresetCookie() {
        this.cookieService.set('selectedTimePreset', JSON.stringify(this.selectedTimePreset));
    }

}
