import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {AccountService} from './account.service';
import {UserService} from './user.service';
import {ApiService} from './api.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthService {
    // public loading = new BehaviorSubject<boolean>(false);
    public isInSession = new BehaviorSubject<boolean>(false);
    public loginError = new BehaviorSubject<string>(null);

    constructor(private api: ApiService,
                private accountService: AccountService,
                private userService: UserService,
                private router: Router) {
        this.checkSession();
        setInterval(() => {
            this.checkSession();
        }, 1800000);
    }

    public logOut() {
        this.api.deleteRequest('/session').subscribe(() => {
            // this.router.navigate
            this.checkSession();
        });
    }

    public logIn(email, password) {
        this.api.postRequest('/login', {
            'email': email,
            'password': password
        }).subscribe((res: any) => {
            console.log(res);
            if (res.error) {
                this.loginError.next(res.error.error);
            } else {
                this.loginError.next(null);
                this.checkSession();
            }
            // this.userService.getUserDetails();
            // this.accountService.getAccountInfo();
        });
    }

    private checkSession() {
        this.api.getRequest('/session').subscribe((res: boolean) => {
            if (res) {
                if (this.userService.user.getValue() == null) {
                    this.userService.getUserDetails();
                }
                if (this.accountService.availableAccounts.getValue() == null) {
                    this.accountService.getAvailableAccounts();
                }
            }
            this.isInSession.next(res);
        });
    }
}
