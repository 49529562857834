import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
    public emailFormControl = new FormControl('', [Validators.required, Validators.email]);
    public passFormControl = new FormControl('', [Validators.required]);
    public hide = true;
    public matcher = new MyErrorStateMatcher();
    public error;

    constructor(private auth: AuthService) {
        this.auth.loginError.subscribe((error) => {
            console.log(error);
            this.error = error;
        });
    }

    ngOnInit() {
    }

    public login() {
        this.auth.logIn(this.emailFormControl.value, this.passFormControl.value);
    }

}
